<template>
  <validation-observer
    ref="refFormObserver"
  >
    <b-modal
      id="modal-edit-balance"
      :title="$t('agency.editBalance.title')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      lazy
      size="lg"
      no-close-on-backdrop
      @show="showHandle"
    >
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mt-lg-25"
          variant="danger"
          pill
          @click="handleResetBalance"
        >
          <span class="align-middle">
            {{ $t('confirmation') }}
          </span>
        </b-button>
      </template>

      <div>
        <b-form
          ref="form"
          class="mb-2"
        >
          <div v-if="agenciesData && agenciesData.creditInfo">
            <div>
              {{ $t('agency.editBalance.balanceNow') }}:
              <span class="font-weight-bolder">{{ formatCurrency(agenciesData.creditInfo.balance) }}</span>
            </div>
            <div>
              {{ $t('agency.editBalance.limitCredit') }}:
              <span class="font-weight-bolder">{{ formatCurrency(agenciesData.creditInfo.limitCredit) }}</span>
            </div>
          </div>
          <div class="mt-50">
            {{ $t('agency.editBalance.editBalanceForAgency') }}
            <span
              v-if="agenciesData"
              class="font-weight-bolder"
            >
              {{ `${agenciesData.agencyName || ''}` }}
            </span>:
          </div>
          <validation-provider
            #default="{ errors }"
            name="Số dư"
            rules="required|integer"
          >
            <b-form-group
              class="mb-0"
              label-for="amount-to-edit"
            >
              <b-form-input
                name="Số dư"
                :value="amountToEdit"
                :state="errors[0] ? false : null"
                class="d-none"
              />
              <IAmInputMoney
                id="amount-to-edit"
                :value-money.sync="amountToEdit"
                class="flex-grow-1"
                :state="errors[0] ? false : null"
                :can-input-negative="true"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>

        <!-- SECTION audit log -->
        <AppCollapse type="border card">
          <AppCollapseItem
            title
            class-header="card-header bg-warning"
          >
            <template #header>
              <span class="text-dark font-weight-bolder">
                {{ $t('agency.auditLog') }}
              </span>
            </template>
            <b-table-lite
              bordered
              responsive
              :sticky-header="true"
              show-empty
              :empty-text="$t('noMatchingResult')"
              no-border-collapse
              small
              class="mb-1 rounded"
              thead-class="text-dark font-weight-bolder text-nowrap"
              :items="agenciesData.auditLogsBalance"
              :fields="tableColumns"
            >
              <template
                v-for="column in tableColumns"
                v-slot:[`head(${column})`]="data"
              >
                <span
                  :key="column"
                  class="text-dark"
                >
                  {{ $t(`agency.auditLogsCredit.${data.column}`) }}
                </span>
              </template>
              <template #cell(no)="data">
                {{ agenciesData.auditLogsBalance.length - Number(data.index) }}
              </template>

              <template #cell(updated)="{item}">
                <div>
                  {{ convertISODateTime(item.updatedAt).dateTime }}
                  <div>
                    {{ $t('by') }} <span class="font-weight-bolder">{{ item.modifiedBy.username }}</span>
                  </div>
                </div>
              </template>
              <template #cell(outstandingBalance)="{item}">
                <div style="min-width: 170px">
                  <div>
                    <span class="text-body-3"><em>Trước khi sửa đổi:</em></span>
                    <div class="font-weight-bold text-right">
                      {{ formatCurrency(JSON.parse(item.oldValue)) ?? 0 }}
                    </div>
                  </div>
                  <div class="border-top mt-25 pt-25">
                    <span class="text-body-3"><em>Sau khi sửa đổi:</em></span>
                    <div class="font-weight-bold text-right">
                      {{ formatCurrency(JSON.parse(item.newValue)) ?? 0 }}
                    </div>
                  </div>
                </div>
              </template>
            </b-table-lite>
          </AppCollapseItem>
        </AppCollapse>
      <!-- !SECTION -->
      </div>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BModal, BButton, BForm, BFormInput, BFormGroup, BFormInvalidFeedback, BTableLite,
} from 'bootstrap-vue'
import { ref, toRefs, watch } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import IAmInputMoney from '@/components/IAmInputMoney.vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  formatCurrency,
  convertISODateTime,
} from '@core/utils/filter'

import useAgenciesHandle from '../useAgenciesHandle'

export default {
  components: {
    BModal,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    IAmInputMoney,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    AppCollapse,
    AppCollapseItem,
    BTableLite,
  },
  props: {
    agenciesData: {
      type: Object,
      required: true,
    },
    currentAgencyId: {
      type: [Number, null],
      default: null,
    },
  },
  setup(props, { emit }) {
    const { resetBalance } = useAgenciesHandle()
    const agenciesData = toRefs(props).agenciesData
    const amountToEdit = ref()

    const resetAmountToEdit = () => {
      amountToEdit.value = agenciesData?.value?.creditInfo?.balance ?? null
    }

    watch(() => [agenciesData], () => {
      amountToEdit.value = agenciesData?.value?.creditInfo?.balance
    }, { immediate: true, deep: true })

    const { refFormObserver, getValidationState } = formValidation(resetAmountToEdit)

    function handleResetBalance() {
      refFormObserver.value.validate()
        .then(success => {
          if (success) {
            const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('agency.editBalance.areYouConfirm') } })
            this.$bvModal
              .msgBoxConfirm(titleMsg(), {
                title: this.$t('confirmation'),
                size: 'sm',
                okVariant: 'danger',
                okTitle: this.$t('confirmation'),
                cancelTitle: this.$t('cancel'),
                cancelVariant: 'outline-danger',
                hideHeaderClose: true,
                centered: true,
              })
              .then(value => {
                if (value) {
                  this.$bvModal.show('modal-api-loading')
                  resetBalance(props.currentAgencyId, { amount: amountToEdit.value })
                    .then(() => this.$bvModal.hide('modal-edit-balance'))
                    .finally(() => {
                      emit('refetchAgency')
                      this.$bvModal.hide('modal-api-loading')
                    })
                }
              })
          }
        })
    }

    function showHandle() {
      resetAmountToEdit()
    }

    const tableColumns = ref(['no', 'updated', 'outstandingBalance'])

    return {
      amountToEdit,
      refFormObserver,
      getValidationState,
      formatCurrency,
      convertISODateTime,
      tableColumns,
      handleResetBalance,
      showHandle,
    }
  },
}
</script>
